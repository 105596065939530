import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=c7d4ad4c&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"
import style0 from "./Header.scss?vue&type=style&index=0&id=c7d4ad4c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7d4ad4c",
  null
  
)

export default component.exports