


















import { Component, Vue, Prop } from 'vue-property-decorator';

import LogoExplicae from '@/assets/imgs/logo/LogoExplicae.vue';
import LogoConquistae from '@/assets/imgs/logo/LogoConquistae.vue';

const INSTITUTION_NAME_DEFAULT = 'Explicaê';

@Component({
  components: {
    LogoExplicae,
    LogoConquistae
  }
})
export default class Header extends Vue {
  @Prop({ default: true }) private isLogo!: boolean;

  private currentTime = '00:00';

  mounted() {
    setInterval(this.timeFunction);
  }

  get isB2b() {
    return !!this.$store.getters.profile.institution?.name;
  }

  get institution() {
    return this.$store.getters.profile.institution;
  }

  get institutionName() {
    return this.institution?.name || INSTITUTION_NAME_DEFAULT;
  }

  get logoB2b() {
    return this.institution?.image;
  }

  timeFunction() {
    const time = new Date();

    this.currentTime = `${String(time.getHours()).padStart(2, '0')}:${String(
      time.getMinutes()
    ).padStart(2, '0')}`;
  }

  handleClickLogo() {
    this.$router.push({
      name: 'Dashboard'
    });
  }
}
