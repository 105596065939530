













import { Component, Vue, Prop } from 'vue-property-decorator';

import Timer from '@/assets/icons/Timer.vue';

@Component({
  components: {
    Timer
  }
})
export default class TextModal extends Vue {
  @Prop({ default: false }) hasTimerIcon!: boolean;
  @Prop() title!: string;
}
